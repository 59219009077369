import React, { useState } from "react"
import { PageLayout } from "../components/Layout"
import TextSectionBackground from "../components/TextSectionBackground"
import { PageHeader } from "../components/Headings 1"
import { SubSectionHeader } from "../components/Headings 2"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { ButtonBasic } from "../components/buttons"
import SEO from "../components/SEO"

const ContactContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 980px) {
    flex-direction: column;
  }
  .contact {
    text-align: left;
    width: 45%;
    @media only screen and (max-width: 980px) {
      width: 100%;
    }
    p {
      color: #696969;
      font-weight: 500;
      line-height: 1em;
      font-size: 16px;
      a {
        text-decoration: none;
        color: #919191;
      }
    }
    .highlighted {
      color: #3c763d;
      background-color: #dff0d8;
      padding: 8px;
      font-size: 14px;
      font-weight: 500;
    }
    form {
      input {
        display: block;
        box-sizing: border-box;
        width: 100%;
        border-radius: 4px;
        border: 1px solid #ccc;
        padding: 10px 15px;
        margin-bottom: 10px;
        font-size: 14px;
      }
      label {
        color: #666;
        line-height: 2;
        text-align: left;
        display: block;
        margin: 10px 0;
        font-weight: 700;
        span {
          color: #ee0000;
        }
      }
      textarea {
        width: 100%;
        height: 75px;
        border-radius: 4px;
      }
      p {
        color: #bf1650;
      }

      p::before {
        display: inline;
        content: "⚠ ";
      }
      button {
        margin-top: 2rem;
      }
      .error-label {
        color: #a94442;
      }
      .error-input {
        border-color: #a94442;
        background-color: #f2dede;
      }
    }
    .small {
      font-size: 15px;
    }
  }
  .map {
    width: 45%;
    @media only screen and (max-width: 980px) {
      width: 100%;
      margin-top: 1rem;
    }
  }
`

const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactUs = () => {
  const [submitted, setSubmitted] = useState(false)
  const { register, errors, handleSubmit } = useForm()
  const onSubmit = (data: any) => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", "Lead")
      }
    }
    setSubmitted(true)
    fetch("https://getform.io/f/15c333d8-05fe-41f6-aed0-e7cc2fc5a16c", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...data }),
    })
      .then(() => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "formSubmission",
          ...data,
        })
      })
      .catch((error) => alert(error))
  }

  const submitFacebook = () => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", "Lead")
      }
    }
  }

  return (
    <PageLayout view={true}>
      <SEO
        title="Contact Us"
        description="Drop us a mail or give us a call, we’ll be happy to assist you. Our preferred and most effective communication channel is via email."
      />
      <TextSectionBackground pa="4% 10%">
        <PageHeader>We Are Expecting You</PageHeader>
        <SubSectionHeader>
          Drop us a mail or give us a call, we’ll be happy to assist you
        </SubSectionHeader>
        <ContactContainer>
          <div className="contact">
            <p>
              Our preferred and most effective communication channel is via
              email, please send us a message or an enquiry to:
            </p>
            <p>
              Email:{" "}
              <a
                onClick={submitFacebook}
                href="mailto:reservations@mavela.co.za"
              >
                reservations@mavela.co.za
              </a>
            </p>
            <p>
              Tel:{" "}
              <a onClick={submitFacebook} href="tel:+27359400996">
                +27 (0)35 940 0996
              </a>{" "}
              or Mobile/Whatsapp{" "}
              <a href="tel:+270723476438">+27 (0)72 347 6438</a>
            </p>
            {submitted ? (
              <p className="highlighted">
                Thanks for your enquiry, we will be in touch with you shortly.
              </p>
            ) : (
              <form name="contact" onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" name="form-name" value="contact" />
                <label htmlFor="name" className={errors.name && "error-label"}>
                  Name: <span>*</span>
                </label>
                <input
                  className={errors.name && "error-input"}
                  type="text"
                  id="name"
                  name="name"
                  placeholder="First & Last Name"
                  ref={register({ required: true })}
                />
                {errors.name && <p>Your input is required</p>}
                <label htmlFor="number">Contact Number:</label>
                <input type="number" id="number" name="number" ref={register} />
                <label
                  className={errors.email && "error-label"}
                  htmlFor="email"
                >
                  Email: <span>*</span>
                </label>
                <input
                  className={errors.email && "error-input"}
                  type="email"
                  name="email"
                  id="email"
                  ref={register({
                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                    required: true,
                  })}
                />
                {errors.email && <p>Your input is required</p>}
                <label
                  className={errors.message && "error-label"}
                  htmlFor="message"
                >
                  Message: <span>*</span>
                </label>
                <textarea
                  id="message"
                  className={errors.message && "error-input"}
                  name="message"
                  ref={register({ required: true })}
                />
                {errors.message && <p>Your input is required</p>}
                <ButtonBasic type="submit">Send</ButtonBasic>
              </form>
            )}
            <p style={{ paddingTop: "20px" }}>
              For marketing related queries please contact: <br />
              <br />
              Email:{" "}
              <a href="mailto:marketing@mavela.co.za">marketing@mavela.co.za</a>
            </p>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d226056.45928592002!2d31.877463312580783!3d-27.713204982789414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1efaca3c017a91b3%3A0xa86152d5e4bc09b0!2sMavela+Game+Lodge!5e0!3m2!1sen!2sza!4v1528837453623"
            className="map"
            height="620"
            frameBorder="0"
            allowFullScreen
            title="Map"
          ></iframe>
        </ContactContainer>
      </TextSectionBackground>
    </PageLayout>
  )
}

export default ContactUs
